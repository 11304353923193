<template>
  <div>
    <view-hero>
      <template slot="title">Dimensiones</template>
      <template slot="description">
        Puedes elegir las mejores dimensiones y preguntas para tu organización.
        Crear, editar y gestionar de manera adecuada tu encuesta de clima
        laboral.
      </template>
    </view-hero>
    <template v-if="sections.length">
      <div class="btn-container">
        <custom-button
          variant="secondary"
          @click="$refs.sortQuestionsModal.open()"
        >
          <font-awesome-icon slot="icon" :icon="icons.sort" />
          Ordenar Preguntas
        </custom-button>

        <custom-button variant="primary" @click="$refs.newSectionModal.open()">
          <font-awesome-icon slot="icon" icon="plus" />
          Nueva Dimensi&oacute;n
        </custom-button>
      </div>

      <div class="cards-container">
        <section-card
          v-for="section in sections"
          :key="section.id"
          :sectionTitle="section.name"
          :numberOfQuestions="section.questionIds.length"
          @click="onSectionClick(section.id)"
          @delete="deleteSection(section.id)"
        />
      </div>

      <div class="flex justify-between">
        <span class="total">Total Dimensiones: {{ sections.length }}</span>
        <span class="total">
          Total Preguntas:
          {{ sections.reduce((amount, s) => amount + s.questionIds.length, 0) }}
        </span>
      </div>
    </template>

    <empty-screen
      v-else
      :icon="icons.emptyScreen"
      @ctaClick="$refs.newSectionModal.open()"
    >
      <template slot="title">No hay Dimensiones</template>
      <template slot="description">
        Presiona el botón inferior para agregar tu primer grupo de preguntas.
      </template>
      <template slot="btnText">Nueva Dimensión</template>
    </empty-screen>

    <new-section-modal ref="newSectionModal" />

    <sort-questions-modal ref="sortQuestionsModal" />

    <section-modal ref="sectionModal" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { faLayerGroup, faSort } from "@fortawesome/free-solid-svg-icons";

import CustomButton from "@/components/CustomButton.vue";
import SectionCard from "@/components/SectionCard.vue";
import NewSectionModal from "./NewSectionModal.vue";
import EmptyScreen from "../EmptyScreen.vue";
import SortQuestionsModal from "./SortQuestionsModal.vue";
import SectionModal from "./SectionModal.vue";
import ViewHero from "../../components/ViewHero.vue";

export default {
  name: "sections",

  components: {
    CustomButton,
    SectionCard,
    NewSectionModal,
    EmptyScreen,
    SortQuestionsModal,
    SectionModal,
    ViewHero
  },

  data: () => ({
    icons: {
      sort: faSort,
      emptyScreen: faLayerGroup
    }
  }),

  computed: {
    ...mapState({
      sections: state => state.sections.sections,
      survey: state => state.survey
    })
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("sections", [
      "updateOrder",
      "fetchSections",
      "deleteSection"
    ]),

    onSectionClick(id) {
      switch (id) {
        case "engagement":
          this.$router.push("dimensions/engagement");
          break;
        case "enps":
          this.$router.push("dimensions/enps");
          break;
        default:
          this.$refs.sectionModal.open(id);
          break;
      }
    },

    editSection(section) {
      this.edittedSection = JSON.parse(JSON.stringify(section));
      this.newSectionModal = true;
    },

    cancelEdit() {
      this.edittedSection = null;
      this.newSectionModal = false;
    },

    async handleSectionsSorting() {
      const sectionsWithOrder = this.sortableSections.map((section, index) => ({
        ...section,
        order: index + 1
      }));

      await this.updateOrder(sectionsWithOrder);

      this.isSorting = false;

      this.setAlert({
        state: "success",
        message: "Orden actualizado"
      });
    }
  },

  mounted() {
    this.fetchSections();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.view {
  background-color: var(--grayColor1);
}

.list-item {
  align-items: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--lengthMd2);
}

.total {
  font-size: 0.8rem;
  color: var(--fontColor3);
}

.hero {
  background-image: url("../../assets/modules/sections.jpg");
}
</style>
