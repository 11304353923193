<template>
  <button @click="$emit('click')" class="card">
    <h4>{{ sectionTitle }}</h4>
    <span>
      {{ `${numberOfQuestions} Pregunta(s)` }}
    </span>
  </button>
</template>

<script>
export default {
  name: "SectionCard",

  props: {
    sectionTitle: {
      required: true,
      type: String
    },

    numberOfQuestions: {
      required: true,
      type: Number
    }
  }
};
</script>

<style scoped>
.card {
  display: block;
  text-align: start;
  padding: var(--lengthMd2);
  background-color: white;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  cursor: pointer;
}

.card:first-child {
  background-color: var(--mainColor1);
  border-color: var(--mainColor1);
}

.card:first-child h4,
.card:nth-child(2) h4 {
  color: white;
}

.card:first-child span,
.card:nth-child(2) span {
  color: var(--grayColor1);
}

.card:nth-child(2) {
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
}

span {
  margin-top: var(--lengthSm1);
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
}

.btn-container {
  margin-left: auto;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}
</style>
