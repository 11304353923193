<template>
  <ValidationObserver
    v-if="isEditing || isNewQuestion"
    v-click-outside="onCancel"
    tag="div"
    v-slot="{ handleSubmit }"
    class="question"
  >
    <form class="question__form" @submit.prevent="handleSubmit(onSubmit)">
      <custom-input
        v-model="text"
        :label="isNewQuestion ? 'Nueva Pregunta' : `Pregunta ${index + 1}`"
        rules="required"
      />

      <checkbox v-model="isOpenQuestion" label="Pregunta Abierta" />

      <div class="btn-container">
        <custom-button type="submit" :disabled="isLoading">
          {{ isLoading ? "Guardando..." : "Guardar" }}
        </custom-button>

        <custom-button
          variant="secondary"
          :disabled="isLoading"
          @click="onCancel"
        >
          Cancelar
        </custom-button>
      </div>
    </form>
  </ValidationObserver>

  <article v-else class="question">
    <div>
      <h4 v-if="question.title" class="question__title">
        {{ question.title }}
      </h4>
      <span class="question__index">
        {{ `Pregunta ${question.id === "enps" ? "Final" : index + 1}` }}
      </span>
      <p class="question__text">{{ question.text }}</p>
    </div>

    <div v-if="isEditable" class="btn-container">
      <icon-button icon="pen" variant="text" @click="editQuestion" />

      <icon-button
        icon="trash-alt"
        variant="text"
        @click="$emit('delete', question.id)"
      />
    </div>
  </article>
</template>

<script>
import vClickOutside from "v-click-outside";

import { mapActions, mapMutations } from "vuex";

import { ValidationObserver } from "vee-validate";

import CustomButton from "./CustomButton.vue";
import CustomInput from "./CustomInput.vue";
import IconButton from "./IconButton.vue";
import Checkbox from "./Checkbox.vue";

export default {
  name: "QuestionCard",

  components: {
    IconButton,
    CustomInput,
    CustomButton,
    Checkbox,
    ValidationObserver
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    question: {
      default: () => ({}),
      type: Object
    },
    isNewQuestion: {
      default: false,
      type: Boolean
    },
    isEditable: Boolean,
    section: Object,
    index: Number
  },

  data: () => ({
    isEditing: false,
    isLoading: false,

    text: "",
    isOpenQuestion: false
  }),

  methods: {
    ...mapActions("sections", ["updateQuestion", "addQuestions"]),

    ...mapMutations(["setAlert"]),

    async onSubmit() {
      try {
        this.isLoading = true;

        if (!this.isNewQuestion) {
          await this.updateQuestion({
            id: this.question.id,
            text: this.text,
            isOpenQuestion: this.isOpenQuestion
          });

          this.isEditing = false;

          this.setAlert({
            state: "success",
            message: "Se actualizó la pregunta"
          });
        } else {
          await this.addQuestions({
            questions: [
              { text: this.text, isOpenQuestion: this.isOpenQuestion }
            ],
            section: this.section
          });

          this.setAlert({
            state: "success",
            message: "Se agregó la pregunta"
          });
        }

        this.onCancel();
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    },

    onCancel() {
      if (this.isNewQuestion) return this.$emit("cancel");
      this.text = "";
      this.isOpenQuestion = false;
      this.isEditing = false;
    },

    editQuestion() {
      this.text = this.question.text;
      this.isOpenQuestion = this.question.isOpenQuestion || false;
      console.log(this.isOpenQuestion);
      this.isEditing = true;
    }
  }
};
</script>

<style scoped>
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lengthMd1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.question + .question {
  margin-top: var(--lengthMd1);
}

.question__form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-gap: var(--lengthMd1);
}

.question__form .btn-container {
  flex-direction: column;
  grid-column: 2 / 3;
  grid-row: 1 / -1;
}

.question__index {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
}

.question__text {
  margin-top: var(--lengthSm1);
  color: var(--fontColor1);
  font-size: var(--lengthSm3);
  font-weight: var(--medium);
}

.question__title {
  margin-bottom: var(--lengthSm1);
  color: var(--mainColor1);
}

.btn-container {
  gap: var(--lengthSm3);
  grid-gap: var(--lengthSm1);
}
</style>
